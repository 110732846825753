import React, { Component, FC, ReactElement, ReactNode, Suspense, FunctionComponent } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Dimmer, Header, Loader } from 'semantic-ui-react';

type TProps = {
    loadingFallback?: string | ReactNode;
    errorFallback?: ReactElement<string | typeof Component | FunctionComponent<{}>>;
    children?: ReactNode;
};

export const SuspenseContainer: FC<TProps> = ({ loadingFallback, errorFallback, children }) => {
    loadingFallback = loadingFallback || (
        <Dimmer active={true} page inverted>
            <Loader/>
        </Dimmer>
    );
    errorFallback = errorFallback || (
        <Dimmer active={true}>
            <Header as="h2" icon inverted>
                Произошла ошибка при загрузке страницы
            </Header>
        </Dimmer>
    );

    return (
        <ErrorBoundary fallback={errorFallback}>
            <Suspense fallback={loadingFallback}>{children}</Suspense>
        </ErrorBoundary>
    );
};
