import { TCurrencyCode, parseCurrencyCode } from '../@types';

export function getCurrencySymbolByCode(code?: string): string | undefined {
    const currencyCode: TCurrencyCode | undefined = parseCurrencyCode(code);
    switch (currencyCode) {
        case 'KZT': return '₸';
        case 'RUB': return '₽';
        case 'USD': return '$';
    }
    return code;
}

export function formatCurrencyValue(value?: number, currencyCode?: string): string | undefined {
    value = value || 0;
    const formattedValue = value.toFixed(2);
    if (!currencyCode) return formattedValue;
    return `${formattedValue} ${getCurrencySymbolByCode(currencyCode)}`
}
