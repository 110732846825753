import React, { FC } from 'react';
import { DateTimePicker as Flatpikr } from '../DateTimePicker';
import { Controller, useFormContext } from 'react-hook-form';
import { Form } from './Form';

type TProps = { name: string; rules: any, error?: string } & any;

export const DateTimePicker: FC<TProps> = (props) => {
    const { name, rules, error, ...restOfProps } = props;
    const { control } = useFormContext();
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ onChange, onBlur, value }) => (
                <Form.Field error={error}>
                    <Flatpikr onChange={(_: any, data: any) => onChange(data)} onBlur={onBlur} defaultValue={value} {...restOfProps} />
                </Form.Field>
            )}
        />
    );
};
