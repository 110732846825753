import React, { FC } from 'react';
import { Button, FormInputProps, Icon } from 'semantic-ui-react';
import { Form } from '.';

type TProps = {
    name: string;
    placeholder: string;
    loading: boolean;
    isItemFound: boolean;
    resetItem: () => void
} & FormInputProps;

export const FindInput: FC<TProps> = (props) => {
    const { name, placeholder, loading, isItemFound, resetItem, ...formInputProps } = props;

    return (
        <Form.Input
            className="find-input"
            autoFocus
            placeholder={placeholder}
            name={name}
            required
            action={
                <Button type="button" disabled={!isItemFound} onClick={resetItem} icon>
                    <Icon className="trash"></Icon>
                </Button>
            }
            icon={isItemFound ? 'check' : undefined}
            iconPosition={isItemFound || loading ? 'left' : undefined}
            loading={loading}
            {...formInputProps}
        />
    );
};
