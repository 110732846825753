import React, { FC, ReactNode } from 'react';
import { Menu } from 'semantic-ui-react';

import { TMenuItem } from '../../@types';
import { MenuItem } from './MenuItem';
import './styles.scss';

type TProps = {
    items: TMenuItem[];
    horizontal?: boolean;
    pointing?: boolean;
    children?: ReactNode;
};

export const PageMenu: FC<TProps> = ({ items, horizontal, pointing, children }) => {
    return (
        <Menu stackable secondary borderless fluid vertical={!horizontal} pointing={pointing} >
            {items.filter(item => !item.hidden).map((item) => (
                <MenuItem {...item} />
            ))}
            {children}
        </Menu>
    );
};

export * from './MenuItem';
