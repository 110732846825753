import React, { FC } from 'react';
import { Form, FormFieldProps, FormInput as SemanticReactFormInput, FormInputProps, Label } from 'semantic-ui-react';
import { Controller, useFormContext } from 'react-hook-form';
import { getCurrencySymbolByCode } from '../../utils';

type TProps = {
    name: string;
    rules: any;
    currency?: string;
    fieldLabel?: string;
    required?: boolean;
} & FormInputProps & FormFieldProps;

export const FormInput: FC<TProps> = (props) => {
    const { control } = useFormContext();
    const { name, rules, currency, fieldLabel, required, ...formInputProps } = props;

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            defaultValue={props.defaultValue}
            render={({ onChange, onBlur, value }) => {
                if (!currency) return <SemanticReactFormInput onChange={onChange} onBlur={onBlur} value={value} required={required} {...formInputProps} />;
                else
                    return (
                        <Form.Field required={required}>
                            {fieldLabel&&<label>{fieldLabel}</label>}
                            <SemanticReactFormInput onChange={onChange} onBlur={onBlur} value={value} {...formInputProps} labelPosition="left">
                                <Label basic>{getCurrencySymbolByCode(currency)}</Label>
                                <input />
                            </SemanticReactFormInput>
                        </Form.Field>
                    );
            }}
        />
    );
};
