export type TJackpotProps = {
    id?: string;
    name?: string;

    amount: number;
    nextAmount?: number;

    progressPercent?: number;
    nextProgressPercent?: number;

    platform?: TPlatform;
    displayType?: TJackpotDisplayType;
    shared?: boolean;

    startedAt?: string;
    assignedAt?: string;
    completedAt?: string;
    paidAt?: string;

    sources?: TJackpotSource[];

    maxAmount: number;
    minAmount: number;
    minBet: number;

    demandTerm?: string;
    onDemandForTerm?: boolean;

    betAmountCoefficient?: number;
    speedTag?: string;
    betPercent?: number;
    nextBetPercent?: number;
};

export type TJackpot = TJackpotProps | undefined;

export enum TJackpotDisplayType {
    INGAME = 'ingame',
    COMMUNITY = 'community'
}

export enum TPlatform {
    MBK = 'mbk',
    RACES = 'hdr',
    ONLINE = 'online'
}

export enum TJackpotSourceType {
    GAME = 'game',
    GAME_TYPE = 'type',
    FEED = 'feed'
}

export type TJackpotSource = {
    code: string;
    type: TJackpotSourceType
    name?: string;
    isOnDevice?: boolean;
    hasInGameJp?: boolean;
    hasCommunityJp?: boolean;
};


