import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { TPlatform, TRouteParams } from '../@types';
import { API_URL_JACKPOTS_SETTINGS, getJackpotsSettings } from '../services';

export function useJackpotsSettings(platformOverride?: TPlatform) {
    const { hallId, platform } = useParams<TRouteParams>();
    const targetPlatform = platformOverride || platform;

    const { data, error, mutate, isValidating } = useSWR(
        JSON.stringify(API_URL_JACKPOTS_SETTINGS(hallId, platform)),
        () => getJackpotsSettings(hallId, targetPlatform),
        { suspense: true }
    );

    useEffect(() => {
        !data && !isValidating && mutate();
    }, [hallId, targetPlatform]);

    return {
        settings: data,
        isLoading: !error && !data,
        error
    };
}
