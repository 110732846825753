import React, { FC, ReactNode } from 'react';
import { Label, LabelProps } from 'semantic-ui-react';
import { CurrencyValue } from '.';

type TProps = {
    icon?: ReactNode;
    label?: string;
    value?: number;
    currency?: string;
    details?: string;
} & LabelProps;

export const ValueLabel: FC<TProps> = (props) => {
    const { icon, label, value, currency, details, ...labelProps } = props;
    const hasValue = value || value === 0;
    return (
        <Label size={labelProps.size || 'mini'} {...labelProps}>
            {icon}
            {label}
            {(hasValue || details) && (
                <Label.Detail>
                    {hasValue && <CurrencyValue value={value} currency={currency} />}
                    {details && <span style={{ textTransform: 'capitalize' }}>{details}</span>}
                </Label.Detail>
            )}
        </Label>
    );
};
