import { getSearchParams } from '../utils/http';

export function getReceiptUrl({ id, number }: { id: string; number: string }) {
    var qs = getSearchParams({ lang: getLang(), offset: getOffset(), number });
    return `/api/support/receipts/${id}/print?${qs}`;
}

function getLang() {
    return 'ru';
}

function getOffset() {
    return `${-1 * new Date().getTimezoneOffset()}`;
}
