import React, { FC, ReactNode, useContext } from 'react';
import { Table, Dimmer, Loader } from 'semantic-ui-react';
import { MediaContext } from '../@contexts';
import { getKey } from '../utils';

type TProps = {
    columns: DataColumnType[];
    isLoading?: boolean;
    items: DataItemType[] | undefined;
};

type DataColumnType = {
    header: string;
    value: string | ((item: DataItemType) => string | number | ReactNode );
};

type DataItemType = {
    [key: string]: string[];
} & any;

export const DataTable: FC<TProps> = ({ columns, items, isLoading }) => {
    const layout = useContext(MediaContext);

    return (
        <>
            {isLoading && (
                <Dimmer active inverted>
                    <Loader inverted>Загрузка</Loader>
                </Dimmer>
            )}

            <Table compact>
                {!layout.isMobile && (
                    <Table.Header>
                        <Table.Row>
                            {columns.map((column) => (
                                <Table.HeaderCell key={getKey(column)}>{column.header}</Table.HeaderCell>
                            ))}
                        </Table.Row>
                    </Table.Header>
                )}
                <Table.Body>
                    {!!items?.length &&
                        items.map((item, rowIndex) => (
                            <Table.Row key={getKey(item, rowIndex)}>
                                {columns.map((column) => (
                                    <Table.Cell key={getKey(column)}>
                                        {layout.isMobile && <strong>{column.header}:</strong>}
                                        {column.value instanceof Function ? column.value(item) : item[column.value]}
                                    </Table.Cell>
                                ))}
                            </Table.Row>
                        ))}
                    {!items?.length && (
                        <Table.Row>
                            <Table.Cell colSpan={columns.length}>Нет данных для отображения</Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        </>
    );
};
