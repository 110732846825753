import { http, getSearchParams } from '../utils/http';
import { TDateRange } from './../@types';
import { formatForApi } from '../utils';

export function getKiosks(searchCategory?: string, searchValue?: string): Promise<any> | undefined {
    if (!searchCategory && !searchValue) return Promise.resolve(undefined);
    return http.get(`/api/support/kiosks/?${searchCategory}=${searchValue}`).then(r => r.data);
}

export function findKiosk(kioskId?: string): Promise<any> | undefined {
    if (!kioskId) return Promise.resolve(undefined);
    return http.get(`/api/support/kiosks/${kioskId}`).then((r) => r.data);
}

export const API_URL_GET_KIOSK = (searchCategory?: string, searchValue?: string) => {
    return `/api/support/kiosks/${searchCategory}/${searchValue}`;
}

export const API_URL_GET_KIOSK_SETTINGS = (kioskId?: string) => {
    return `/api/support/kiosks/id/${kioskId}`;
}

export const updateKiosk = (kioskId?: string) => {
    return http.post(`/api/support/kiosks/${kioskId}/commands/update`).then((r) => r.data);
}

export const updateKioskGames = (kioskId?: string) => {
    return http.post(`/api/support/kiosks/${kioskId}/games-content/update-games`).then((r) => r.data);
}

export const lockKiosk = (kioskId?: string) => {
    return http.post(`/api/support/kiosks/${kioskId}/lock`).then((r) => r.data);
}

export const unlockKiosk = (kioskId?: string) => {
    return http.post(`/api/support/kiosks/${kioskId}/unlock`).then((r) => r.data);
}

export const rebootKiosk = (kioskId?: string) => {
    return http.post(`/api/support/kiosks/${kioskId}/commands/reboot`).then((r) => r.data);
}

export const downloadLogsKiosk = (kioskId?: string) => {
    return http.post(`/api/support/kiosks/${kioskId}/commands/logs`).then((r) => r.data);
}

export const getGamesTransactions = (kioskId?: string, dateRange?: TDateRange, currentPage?: any) => {
    const search = dateRange && getSearchParams({start: formatForApi(dateRange.start), end: formatForApi(dateRange.end), pageNumber: currentPage.pageNumber})
    return http.get(`/api/support/kiosks/${kioskId}/games/transactions?${search || ''}`).then((r) => r.data);
}

export const API_URL_GET_GAMES_TRANSACTIONS = (kioskId?: string, dateRange?: TDateRange, currentPage?: any) => {
    const search = dateRange && getSearchParams({start: formatForApi(dateRange.start), end: formatForApi(dateRange.end), pageNumber: currentPage.pageNumber})
    return `/api/support/kiosks/transactions/games/${kioskId}?${search || ''}`;
}

export const getOperationsTransactions = (kioskId?: string, dateRange?: TDateRange) => {
    const search = dateRange && getSearchParams({start: formatForApi(dateRange.start), end: formatForApi(dateRange.end)})
    return http.get(`/api/support/kiosks/${kioskId}/transactions?${search || ''}`).then((r) => r.data);
}

export const API_URL_GET_OPERATIONS_TRANSACTIONS = (kioskId?: string, dateRange?: TDateRange) => {
    const search = dateRange && getSearchParams({start: formatForApi(dateRange.start), end: formatForApi(dateRange.end)})
    return `/api/support/kiosks/transactions/operations/${kioskId}?${search || ''}`;
}

export const getReleasesList = () => {
    return http.get('/api/support/kiosks/releases').then((r) => r.data);
}

export const API_URL_GET_RELEASES_LIST = () => '/api/support/kiosks/releases';

export const changeActualVersionKiosk = (kioskId?: string) => {
    return http.post(`/api/support/kiosks/releases/${kioskId}/actual`).then((r) => r.data);
}
