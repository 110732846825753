import { useEffect } from 'react';
import { format, parse, isMatch } from 'date-fns';
import { useForm } from 'react-hook-form';

import { useRouting } from '.';
import { dateFormFormat, dateRangeToQueryString, dateTimeFormFormat } from '../utils';
import { TDateRange } from '../@types';

export function useDateRangeForm() {
    const { pathname, push, search, dateRange } = useRouting();

    const formMethods = useForm<{ start: string; end: string }>({
        defaultValues: {
            start: format(dateRange.start, dateTimeFormFormat),
            end: format(dateRange.end, dateTimeFormFormat)
        }
    });

    useEffect(() => {
        formMethods.reset({
            start: format(dateRange.start, dateTimeFormFormat),
            end: format(dateRange.end, dateTimeFormFormat)
        });
    }, [search]);

    async function onSubmit({ start, end }: { start: string; end: string }) {
        const newDateRange: TDateRange = {
            start: parse(start, isMatch(start, dateTimeFormFormat) ? dateTimeFormFormat : dateFormFormat, new Date()),
            end: parse(end, isMatch(end, dateTimeFormFormat) ? dateTimeFormFormat : dateFormFormat, new Date())
        };

        push(`${pathname}?${dateRangeToQueryString(newDateRange)}`);
    }

    return { ...formMethods, onSubmit: formMethods.handleSubmit(onSubmit) };
}
