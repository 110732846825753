import React, { FC } from 'react';
import { Divider as SemanticReactDivider, DividerProps as SemanticReactDividerProps } from 'semantic-ui-react';
import './styles.scss';

type DividerProps = {
    custom?: boolean;
};

export const Divider: FC<SemanticReactDividerProps | DividerProps> = (props) => {
    const { custom, ...dividerProps } = props;
    return <SemanticReactDivider {...dividerProps} className={custom ? 'custom' : undefined} />;
};
