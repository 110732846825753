import { Notyf } from 'notyf';
const notyf = new Notyf({
    duration: 3500,
    ripple: true,
    position: {
        x: 'right',
        y: 'top'
    },
    types: [
        {
            type: 'warning',
            background: 'orange',
            icon: {
                className: 'material-icons',
                tagName: 'i',
                text: 'warning'
            },
            duration: 10000,
            dismissible: true
        },
        {
            type: 'error',
            background: 'indianred',
            duration: 10000,
            dismissible: true
        }
    ]
});

export function ok(msg?: string) {
    notyf.success({ message: msg });
}

export function warn(msg?: string) {
    notyf.open({ type: 'warning', message: msg });
}

export function error(msg?: string) {
    notyf.error({ message: msg });
}

export const notify = {
    ok,
    warn,
    error
};
