// simplest insecure hashing for DOM nodes keys
export function getKey(value: any, index?: number): string {
    var hash = 0;
    if (!value) {
        return `${hash}`;
    }

    const valueString = JSON.stringify(value);
    for (var i = 0; i < valueString.length; i++) {
        var char = valueString.charCodeAt(i);
        hash = ((hash<<5)-hash)+char;
        hash = hash & hash;
    }
    return `${hash}-${index}`;
}
