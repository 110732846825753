import React, { FC } from 'react';
import { NavLink, Route, RouteComponentProps, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'semantic-ui-react';
import { TRouteParams } from '../@types';
import { useHall, useReceipt } from '../hooks';

const pathNames: { [key: string]: string } = {
    'receipt': 'Проверка чека',
    hall: 'Зал',
    settings: 'Настройки',
    'users-activity': 'Действия пользователей',
    'device-activity': 'Активность устройства',
    games: 'Игры',
    'live-games': 'Live игры',
    'fast-games': 'Быстрые игры',
    transactions: 'Пополнение/списание',
    actions: 'События',
    jackpots: 'Джекпоты',
    jackpot: 'Джекпот',
    create: 'Создание',
    edit: 'Редактирование',
    mbk: 'MBK',
    hdr: 'HD Races',
    online: "Bet'N Go",
    'sport-live-client': "Спорт: Live Client",
    kiosks: 'Поиск киосков',
    byagent: 'Агент',
    bylogin: 'Логин',
    byhall: 'Зал',
    id: 'id',
    'transactions-games': 'Игровые трансакции',
    'transactions-operations': 'Операционные трансакции',
    filter: 'Фильтр',
    multiple: 'Множественный',
    'common-settings': 'Управление киосками'
};

export const Breadcrumbs: FC = () => {
    return (
        <Breadcrumb size="big" style={{ marginLeft: '15px', display: 'flex', alignItems: 'center' }}>
            <Route path="/:path" component={BreadcrumbsItem} />
        </Breadcrumb>
    );
};

const BreadcrumbsItem: FC<RouteComponentProps<TRouteParams>> = ({ match }) => {

    const urlParts = getUrlParts(match.url);
    const lastPartIndex = urlParts.length - 1;

    const { hall } = useHall(urlParts[lastPartIndex - 1] === 'hall' ? urlParts[lastPartIndex] : undefined);
    const { receipt } = useReceipt(urlParts[lastPartIndex - 1] === 'receipt' ? urlParts[lastPartIndex] : undefined);
    const location = useLocation();

    const getEntityIdLabel = (entityName: string, entityId: string) => {
        switch (entityName) {
            case 'hall':
                return hall?.number;
            case 'receipt':
                return receipt?.receipt?.number;
            case 'multiple':
                    return '';
            default:
                return entityId;
        }
    };

    const matchUrlPart = urlParts[lastPartIndex];
    let pathName = pathNames[matchUrlPart];
    if (!pathName) {
        const entityName = urlParts[lastPartIndex - 1];
        const entityId = matchUrlPart;
        const entityIdLabel = getEntityIdLabel(entityName, entityId);

        pathName = pathNames[urlParts[lastPartIndex - 1]] + (entityIdLabel ? ` [${entityIdLabel}]` : '');
    }

    const locationParts = getUrlParts(location.pathname);
    const matchPartNumber = locationParts.indexOf(matchUrlPart);

    const nextIsPartWithParams = !match.isExact && !pathNames[locationParts[matchPartNumber + 1]];

    return (
        <React.Fragment>
            {!nextIsPartWithParams && (
                <Breadcrumb.Section as={!match.isExact ? NavLink : null} to={`${match.url}${location.search}`}>
                    {pathName}
                </Breadcrumb.Section>
            )}
            {!nextIsPartWithParams && !match.isExact && <Breadcrumb.Divider icon="right chevron" />}
            <Route path={`${match.url}/:path`} component={BreadcrumbsItem} />
        </React.Fragment>
    );
};

function getUrlParts(url: string): string[] {
    return !!url ? url.split('/').filter((p) => !!p) : [];
}
