import { TCredentials, TUser } from './../@types';
import { http } from '../utils/http';

export const API_URL_LOGIN = '/api/user/login';
export function login(credentials: TCredentials): Promise<void> {
    return http.post(API_URL_LOGIN, { userName: credentials?.login, password: credentials?.password });
}

export const API_URL_LOGOUT = '/api/user/logout';
export function logout(): Promise<void> {
    return http.get(API_URL_LOGOUT);
}

export const API_URL_GET_USER = '/api/support/user';
export async function getUser(): Promise<TUser> {
    return (await http.get<TUser>(API_URL_GET_USER))?.data;
}
