export enum TReceiptState {
    RECEIPT_NOT_CALCULATED = 'not-calculated',
    RECEIPT_PAID = 'paid',
    RECEIPT_WON = 'won',
    RECEIPT_LOST = 'lost'
}

export type TReceiptProps = {
    id: string;
    number: string;
    calcAt: string;
    paidAt: string;
    bet: number;
    win: number;
    expectedWin: number;
    state: TReceiptState;
    pin: {};
    online: {};
};
export type TReceipt = TReceiptProps | undefined;
