import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { TRouteParams } from '../@types';
import { API_URL_GET_HALL, getHall } from '../services';

export function useHall(hallIdOverride?: string) {
    const { hallId } = useParams<TRouteParams>();
    const targetHallId = hallIdOverride || hallId;

    const { data, error, mutate } = useSWR(JSON.stringify(API_URL_GET_HALL(targetHallId)), () => getHall(targetHallId));

    return {
        hall: data,
        isLoading: !error && !data,
        error,
        mutate
    };
}
