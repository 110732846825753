import { TReceipt, TReceiptResult } from './../@types';
import { http } from '../utils/http';

export function findReceipt(receiptNumber?: string): Promise<TReceipt> {
    return http.get(`/api/support/receipts/find/${receiptNumber}`).then(r => r.data);
}

export const API_URL_GET_RECEIPT = (receiptId?: string) => `/api/support/receipts/${receiptId}`;
export function getReceipt(receiptId?: string): Promise<TReceiptResult> {
    return http.get(API_URL_GET_RECEIPT(receiptId)).then(r => r.data && r.data[0]);
}
