import useSWR from 'swr';
import { API_URL_GET_RELEASES_LIST, getReleasesList } from '../services';

export function useReleases() {
    const { data, error, mutate } = useSWR(JSON.stringify(API_URL_GET_RELEASES_LIST()), () => getReleasesList());

    return {
        releases: data,
        isLoading: !error && !data,
        error,
        mutate
    };
}
