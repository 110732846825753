import { Form as SemanticReactForm } from 'semantic-ui-react';
import { FormInput } from './FormInput';
import { FormCheckbox } from './FormCheckbox';
import { Dropdown } from './Dropdown';
import { RadioGroup } from './RadioGroup';
import { DateTimePicker } from './DateTimePicker';
import { FindInput } from './FindInput'
import './styles.scss'

export const Form = Object.assign(SemanticReactForm, { Input: FormInput, Checkbox: FormCheckbox, Dropdown: Dropdown, RadioGroup: RadioGroup, DateTimePicker, FindInput });
