import useSWR, { mutate as mutateRoot } from 'swr';
import { useParams } from 'react-router-dom';

import { API_URL_LIST_JACKPOTS, listJackpots } from '../services';
import { TPlatform, TRouteParams } from '../@types';

export function useJackpots(platformOverride?: TPlatform) {
    const { hallId, platform } = useParams<TRouteParams>();
    const targetPlatform = platformOverride || platform;

    const { data, error, mutate } = useSWR(
        API_URL_LIST_JACKPOTS(hallId, targetPlatform),
        () => listJackpots(hallId, targetPlatform),
        { suspense: true }
    );

    return {
        jackpots: data,
        mutateAll: () => mutateRoot(API_URL_LIST_JACKPOTS(hallId), null, true),
        mutate,
        isLoading: !error && !data,
        error
    };
}
