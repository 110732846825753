import React, { FC, ReactNode } from 'react';
import { Button as SemanticReactButton, ButtonProps, SemanticCOLORS } from 'semantic-ui-react';
import './styles.scss';

type TProps = {
    hover?: { color: SemanticCOLORS } & ButtonProps;
    children?: ReactNode;
} & ButtonProps;

export const Button: FC<TProps> = ({ hover, children, ...props }) => {
    if (!hover) return <SemanticReactButton {...props}>{children}</SemanticReactButton>;
    const { color, ...hoverProps } = hover;

    return (
        <SemanticReactButton as="div" className={'hover-container'} {...props}>
            <SemanticReactButton {...props} basic className={'hide-on-hover'}>
                {children}
            </SemanticReactButton>
            <SemanticReactButton {...hoverProps} color={hover.color} className={'show-on-hover'}>
                {children}
            </SemanticReactButton>
        </SemanticReactButton>
    );
};
