import { createContext } from 'react';
import { TMediaContext } from '../@types';
import { createMedia } from '@artsy/fresnel';

export const MediaContext = createContext<TMediaContext>({
    isMobile: false
});

export const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
        desktop: 1200
    }
});
