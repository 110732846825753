import React, { FC, useState, useEffect, useContext } from 'react';
import { Card, Dimmer, Loader } from 'semantic-ui-react';

import { PrintingContext, MediaContext } from '../@contexts';
import { Receipt } from '../@components';
import { TReceipt } from '../@types';
import { getReceiptUrl } from '../services';

const RECEIPT_IFRAME_ID = 'bet-history-receipt-frame';

type TProps = {
    receipt: TReceipt;
    showPrintButton?: boolean;
};

export const ReceiptPreviewer: FC<TProps> = ({ receipt, showPrintButton }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [receiptWidth, setReceiptWidth] = useState(200);
    const printing = useContext(PrintingContext);
    const layout = useContext(MediaContext);

    const receiptUrl = receipt && getReceiptUrl(receipt);

    useEffect(() => {
        setIsLoading(true);
    }, [receiptUrl]);

    const print = () => {
        receiptUrl && printing.showPrintWindow(receiptUrl);
    };

    const onReceiptLoaded = (iframe: HTMLIFrameElement) => {
        setReceiptWidth(iframe.clientWidth);
        setIsLoading(false);
    };

    if (!receiptUrl) return null;
    return (
        <Card style={{ width: layout.isMobile ? '100%' : `calc(${receiptWidth}px + 20px)`, margin: 'auto' }}>
            <Card.Content>
                <Card.Description>
                    {isLoading && (
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                    )}
                    <Receipt receiptUrl={receiptUrl} containerId={RECEIPT_IFRAME_ID} onReceiptLoaded={onReceiptLoaded} />
                </Card.Description>
            </Card.Content>
            {showPrintButton && (
                <Card.Content extra>
                    <button className="button button-block" type="button" onClick={print}>
                        ReceiptPrinter
                    </button>
                </Card.Content>
            )}
        </Card>
    );
};
