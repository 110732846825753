import useSWR from 'swr';

import { API_URL_GET_DEVICE_ACTIVITY, getDeviceActivity } from '../services';
import { useDevice } from './useDevice';
import { useRouting } from './useRouting';

export function useDeviceActivity() {
    const { device } = useDevice();
    const { deviceId, dateRange } = useRouting();

    const { data, error, mutate } = useSWR(API_URL_GET_DEVICE_ACTIVITY(device?.type, deviceId, dateRange), () =>
        device ? getDeviceActivity(device?.type, deviceId!, dateRange) : undefined
    );

    return {
        deviceActivity: data,
        isLoading: !error && !data,
        mutate,
        error
    };
}
