import axios from 'axios';

import { THttpErrorMessageDictionary, THttpErrorCode, THttpError } from './../@types';
import { warn, error } from '../services';
import { replaceTemplateValues } from './string';

let commonExceptionsReadingInterval: NodeJS.Timeout;
let commonExceptions: any[] = [];

export function getSearchParams(params: { [key: string]: any }) {
    if (!params) return;
    for (var propName in params) {
        if (params[propName] === null || params[propName] === undefined) {
            delete params[propName];
        }
    }

    return new URLSearchParams(params).toString();
}

axios.interceptors.response.use(
    function (response) {
        if (response?.data?.warning) {
            warn(response.data.warning);
        }
        return response;
    },
    function (err) {
        if (!err?.response?.status || err?.response?.status >= 500) {
            archiveCommonException(err);
            return Promise.reject(err);
        }

        return Promise.reject(err);
    }
);

function archiveCommonException(ex: any) {
    commonExceptions.push(ex);

    if (!commonExceptionsReadingInterval) {
        commonExceptionsReadingInterval = setInterval(() => {
            readCommonExceptionsArchive();
        }, 60000);
        setTimeout(() => readCommonExceptionsArchive(), 500);
    }
}

function readCommonExceptionsArchive() {
    if (!commonExceptions.length) {
        clearInterval(commonExceptionsReadingInterval);
        return;
    }

    error('Произошла ошибка');
    console.error('APP ERROR 1', JSON.stringify(commonExceptions));
    commonExceptions = [];
}

function getBlob<T>(url: string): Promise<T> {
    return axios.get(url, { responseType: 'blob' });
}

export function handleError(err: THttpError, logout: () => void, msg?: string | THttpErrorMessageDictionary) {
    if (!err) {
        archiveCommonException('APP ERROR EMPTY');
        return;
    }

    if (!err.response?.status) {
        error('Произошла ошибка');
        console.error('APP ERROR 2', err);
        return;
    }

    if (err.response.status === 401) {
        logout();
        return;
    }
    const hasErrorMessages = typeof msg === 'object';
    if (err.response.status < 500 &&!!err.response?.data?.code) {
        if (!hasErrorMessages) {
            warn((msg as string) || err.response?.data?.message);
            return;
        }

        const errorMessage = (msg as THttpErrorMessageDictionary)[err.response?.data?.code]
        if (errorMessage) {
            warn(replaceTemplateValues(errorMessage, err.response?.data?.values));
            return;
        } else {
            if (err.response?.data?.code === THttpErrorCode.BAD_REQUEST) {
                warn(err.response?.data?.message);
            } else {
                warn((msg as THttpErrorMessageDictionary)[THttpErrorCode.COMMON]);
            }

            console.error('APP ERROR 3', err.response);
        }
    }

    error('Произошла ошибка');
    console.error('APP ERROR 10', err.response);
}

export const http = { ...axios, getBlob };
