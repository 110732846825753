import useSWR from 'swr';
import { useParams } from 'react-router-dom';

import { API_URL_GET_RECEIPT, getReceipt } from '../services';
import { TRouteParams } from '../@types';

export function useReceipt(receiptIdOverride?: string) {
    const { receiptId } = useParams<TRouteParams>();
    const targetReceiptId = receiptIdOverride || receiptId;

    const { data, error } = useSWR(API_URL_GET_RECEIPT(targetReceiptId), () => !!targetReceiptId ? getReceipt(targetReceiptId) : undefined);

    return {
        receipt: data,
        isLoading: !!targetReceiptId && !error && !data,
        error
    };
}
