import React, { FC } from 'react';
import { FormCheckbox as SemanticReactFormCheckbox, FormCheckboxProps } from 'semantic-ui-react';
import { Controller, useFormContext } from 'react-hook-form';

type TProps = { name: string } & FormCheckboxProps;

export const FormCheckbox: FC<TProps> = (props) => {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={props.name}
            render={({ onChange, onBlur, value }) => (
                <SemanticReactFormCheckbox onChange={(_, data) => onChange(data.checked)} onBlur={onBlur} checked={value} {...props} />
            )}
        />
    );
};
