import { TRouteParams } from './../@types';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { API_URL_GET_KIOSK_SETTINGS, findKiosk } from '../services';

export function useKioskSettings() {
    const { kioskId } = useParams<TRouteParams>();

    const { data, error, mutate } = useSWR(JSON.stringify(API_URL_GET_KIOSK_SETTINGS(kioskId)), () => findKiosk(kioskId));

    return {
        kioskSettings: data,
        error,
        mutate
    }
}
