import { useParams } from 'react-router-dom';
import { mapJackpot } from '../@pages/MainPage/HallPage/JackpotsPage/@common/utils';
import { TJackpot, TRouteParams } from '../@types';
import { useJackpots } from './useJackpots';

export function useJackpot(jackpotIdOverride?: string) {
    const { jackpotId } = useParams<TRouteParams>();
    const targetJackpotId = jackpotIdOverride || jackpotId;

    const { jackpots, isLoading, error, mutate } = useJackpots();
    const jackpot = targetJackpotId && jackpots && jackpots.length ? jackpots.find((jp) => jp?.id === targetJackpotId) : undefined;

    return {
        jackpot: jackpot ? mapJackpot(jackpot) : undefined,
        isLoading,
        mutate: (jackpot: TJackpot) => mutate([...(jackpots?.filter((jp) => jp?.id !== jackpot?.id) || []), jackpot]),
        error
    };
}
