import React, { FC } from 'react';
import { FormRadio, FormRadioProps } from 'semantic-ui-react';
import { Controller, useFormContext } from 'react-hook-form';
import { getKey } from '../../utils';

type TProps = {
    property: string;
    items: TRadioGroupItem[];
} & FormRadioProps;

type TRadioGroupItem = {
    label: string;
    description?: string;
    value: string | number | boolean;
    disabled?: boolean;
};

export const RadioGroup: FC<TProps> = (props) => {
    const { property, items } = props;
    const { control } = useFormContext();

    return (
        <Controller
            name={property}
            control={control}
            render={({ onChange, onBlur, value }) => {
                if (items.find((i) => i.value === value)?.disabled) {
                    onChange(items.find((i) => !i.disabled)?.value);
                }

                return (
                    <>
                        {items.map((item, index) => (
                            <FormRadio
                                key={getKey(`${property}_radioGroupButton`, index)}
                                name={`${property}_radioGroupName`}
                                disabled={item.disabled}
                                label={item.label}
                                value={`${item.value}`}
                                checked={value == item.value}
                                onChange={() => onChange(item.value)}
                                onBlur={onBlur}
                                {...props}
                            />
                        ))}
                    </>
                );
            }}
        />
    );
};
