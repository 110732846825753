
import { createContext } from 'react';
import { TPrintingContext } from '../@types';

export const PrintingContext = createContext<TPrintingContext>({
    isPrintWindowShown: false,
    printUrl: undefined,
    showPrintWindow: () => {},
    hidePrintWindow: () => {}
});
