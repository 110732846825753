import { TRouteParams } from './../@types';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { API_URL_GET_GAMES_TRANSACTIONS, API_URL_GET_OPERATIONS_TRANSACTIONS, getGamesTransactions, getOperationsTransactions } from '../services';
import { useRouting } from './useRouting';

export function useKioskGamesTransactions() {
    const { kioskId } = useParams<TRouteParams>();
    const { dateRangeTransactions, pageNumber } = useRouting();

    const { data, error } = useSWR(JSON.stringify(API_URL_GET_GAMES_TRANSACTIONS(kioskId, dateRangeTransactions, pageNumber)), () => getGamesTransactions(kioskId, dateRangeTransactions, pageNumber));

    return {
        transactions: data,
        kioskId: kioskId,
        dateStart: dateRangeTransactions?.start,
        dateEnd: dateRangeTransactions?.end,
        error,
    };
};

export function useKioskOperationsTransactions() {
    const { kioskId } = useParams<TRouteParams>();
    const { dateRangeTransactions } = useRouting();

    const { data, error } = useSWR(JSON.stringify(API_URL_GET_OPERATIONS_TRANSACTIONS(kioskId, dateRangeTransactions)), () => getOperationsTransactions(kioskId, dateRangeTransactions));

    return {
        transactions: data,
        dateStart: dateRangeTransactions?.start,
        dateEnd: dateRangeTransactions?.end,
        error,
    };
};
