import { TPlatform } from "../@types/Jackpot";
import { useJackpotsSettings } from './useJackpotsSettings';

export function useJackpotsPlatformSettings(platformOverride?: TPlatform) {
    const {settings, isLoading, error } = useJackpotsSettings(platformOverride);
    return {
        settings: settings && settings.length === 1 ? settings[0] : undefined,
        isLoading,
        error
    };
}
