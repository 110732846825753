import { TDeviceType, TDateRange, TDeviceActivity, TDeviceProps } from '../@types';
import { http, getSearchParams } from '../utils/http';
import { formatForApi } from '../utils';

export function findDevice(hallId?: string, deviceLogin?: string): Promise<TDeviceProps> {
    return http.get(`/api/support/halls/${hallId}/devices/find/${deviceLogin}`).then((r) => r.data);
}

export const API_URL_GET_DEVICE = (hallId?: string, deviceId?: string) => `/api/support/halls/${hallId}/devices/${deviceId}`;
export function getDevice(hallId: string, deviceId: string): Promise<TDeviceProps> {
    return http.get(API_URL_GET_DEVICE(hallId, deviceId)).then((r) => r.data);
}

export const API_URL_GET_DEVICE_ACTIVITY = (deviceType?: TDeviceType, deviceId?: string, dateRange?: TDateRange) =>
    `/api/support/devices/${deviceType !== TDeviceType.LEGACY ? 'v2/' : ''}${deviceId}/activity?${
        dateRange && getSearchParams({ start: formatForApi(dateRange.start), end: formatForApi(dateRange.end) })
    }`;
export function getDeviceActivity(deviceType?: TDeviceType, deviceId?: string, dateRange?: TDateRange): Promise<TDeviceActivity> {
    return http.get(API_URL_GET_DEVICE_ACTIVITY(deviceType, deviceId, dateRange)).then((r) => r.data);
}

export function getDeviceActivityExcel(id: string, dateRange?: TDateRange) {
    const { start, end } = dateRange || {};
    var qs = getSearchParams({ start: formatForApi(start), end: formatForApi(end) });
    return http.getBlob(`/api/support/devices/v2/${id}/activity/excel?${qs}`).then((r: any) => r.data);
}

export function getTermActivityExcel(id: string, dateRange?: TDateRange) {
    const { start, end } = dateRange || {};
    var qs = getSearchParams({ start: formatForApi(start), end: formatForApi(end) });
    return http.getBlob(`/api/support/devices/${id}/activity/excel?${qs}`).then((r: any) => r.data);
}
