import { parse } from 'date-fns';
import { TDateRange } from './../@types/TDateRange';
import { dateTimeUrlFormat } from './date';

export function getDateRangeFromSearchQueryParams(search: string): TDateRange | undefined {
    const params = search ? new URLSearchParams(search) : undefined;
    if (!params) return undefined;

    const startParam = params.get('start');
    const endParam = params.get('end');

    if (!startParam || !endParam) return undefined;

    try {
        return { start: parse(startParam, dateTimeUrlFormat, new Date()), end: parse(endParam, dateTimeUrlFormat, new Date()) };
    } catch (e) {
        console.error(`Could not parse date range from ${search}`);
        return undefined;
    }
}

export function getPageNumberFromSearchQueryParams(search: string): any | undefined {
    const params = search ? new URLSearchParams(search) : undefined;
    if (!params) return undefined;

    const pageNumberParam = params.get('pageNumber');

    if (!pageNumberParam) return undefined;

    try {
        return { pageNumber: pageNumberParam };
    } catch (e) {
        console.error(`Could not parse date range from ${search}`);
        return undefined;
    }
}
