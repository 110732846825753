export type TDeviceProps = {
    id: string;
    login: string;
    type: TDeviceType;
    disabled: boolean;
    locked: boolean;
    registered: boolean;
    realBalance: number;
    bonusBalance: number;
    gameBalance: number;
};
export type TDevice = TDeviceProps | undefined;

export enum TDeviceType {
    LEGACY = 'legacy',
    BETNGO = 'bng',
    INBET = 'ib'
}
