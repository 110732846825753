import { useHistory, useLocation, useParams } from 'react-router-dom';
import { TDateRange, TRouteParams } from '../@types';
import { defaultDateRange, getDateRangeFromSearchQueryParams, defaultDateRangeTransactions, getPageNumberFromSearchQueryParams } from '../utils';
import { useAuth } from './useAuth';

type TResult = {
    goBack: () => void;
    push: (path: string) => void;
    logout: () => void;
    search: string;
    pathname: string;
    dateRange: TDateRange;
    dateRangeTransactions: TDateRange;
    pageNumber: any;
} & TRouteParams;

export function useRouting(): TResult {
    const { hallId, platform, deviceId, receiptId, jackpotId, kioskSearchCategory, kioskSearchValue, kioskId, multipleKioskId } = useParams<TRouteParams>();
    const { goBack, push } = useHistory();
    const { logout } = useAuth();
    const { search, pathname } = useLocation();
    const dateRange = getDateRangeFromSearchQueryParams(search) || defaultDateRange;
    const dateRangeTransactions = getDateRangeFromSearchQueryParams(search) || defaultDateRangeTransactions;
    const pageNumber = getPageNumberFromSearchQueryParams(search) || 0;

    return {
        hallId,
        platform,
        deviceId,
        receiptId,
        jackpotId,
        goBack,
        push,
        logout,
        search,
        pathname,
        dateRange,
        dateRangeTransactions,
        pageNumber,
        kioskSearchCategory,
        kioskSearchValue,
        kioskId,
        multipleKioskId
    };
}
