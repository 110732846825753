import useSWR from 'swr';

import { API_URL_GET_USERS_ACTIVITY, getUsersActivity } from '../services';
import { useRouting } from './useRouting';

export function useUsersActivity() {
    const { hallId, dateRange } = useRouting();
    const { data, error } = useSWR(API_URL_GET_USERS_ACTIVITY(hallId, dateRange), () => getUsersActivity(hallId, dateRange));

    return {
        usersActivity: data,
        isLoading: !error && !data,
        error
    };
}
