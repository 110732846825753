import { useState, useEffect, useContext } from 'react';

import { AuthContext } from '../@contexts';
import { TAuthContext, TUser, TCredentials } from '../@types';

import { login as requestLogin, logout as requestLogout, getUser as requestGetUser } from '../services';
import { handleError } from '../utils'

export const useAuth = () => {
    return useContext(AuthContext);
};

export function useAuthContext(): TAuthContext {
    const [state, setState] = useState<{ user?: TUser; isAuth: boolean }>({
        user: localStorage.getItem('u') && localStorage.getItem('u') !== 'undefined' ? JSON.parse(localStorage.getItem('u')!) : undefined,
        isAuth: false
    });
    const { user, isAuth } = state;

    async function login(credentials: TCredentials) {
        try {
            await requestLogin(credentials);
            await setUser();
        } catch (e) {
            handleError(e, logout, 'Введён неверный логин или пароль');
            setState({ isAuth: false });
        }
    }

    async function logout() {
        try {
            await requestLogout();
        } finally {
            localStorage.removeItem('u');
            setState({ isAuth: false });
        }
    }

    async function setUser() {
        const apiUser = await requestGetUser();

        if (!user) {
            localStorage.setItem('u', JSON.stringify(apiUser));
        }

        setState({ user: apiUser, isAuth: true });
    }

    useEffect(() => {
        async function tryGetUser() {
            try {
                await setUser();
            } catch {
                localStorage.removeItem('u');
                setState({ isAuth: false });
            }
        }

        if (!!user) {
            tryGetUser();
        }
    }, []);

    return {
        user,
        login,
        logout,
        isAuth
    };
}
