import { isMatch, parse } from 'date-fns';
import React, { FC, ReactNode } from 'react';
import { FormProvider } from 'react-hook-form';
import { useDateRangeForm } from '../../hooks';
import { dateFormFormat, dateTimeFormFormat } from '../../utils';
import { Button } from '../Button';
import { Form } from '.';
import { Label } from 'semantic-ui-react';

type TProps = {
    time?: boolean;
    loading?: boolean;
    children?: ReactNode;
};

export const DateRangeForm: FC<TProps> = ({ time, loading, children }) => {
    const formMethods = useDateRangeForm();
    const { watch, onSubmit, errors } = formMethods;
    return (
        <FormProvider {...formMethods}>
            <Form size="large" onSubmit={onSubmit}>
                <Form.Group widths="equal" style={{ marginBottom: !!errors?.start || !!errors?.end ? '5px' : 'unset' }}>
                    <Form.DateTimePicker
                        error={errors?.start && 'Дата начала периода должна быть раньше даты окончания'}
                        name="start"
                        data-enable-time={time}
                        rules={{
                            validate: (v: string) => toDate(v) < toDate(watch('end'))
                        }}
                    />
                    <Form.DateTimePicker
                        error={errors?.end && 'Дата начала периода должна быть раньше даты окончания'}
                        name="end"
                        data-enable-time={time}
                        rules={{
                            validate: (v: string) => toDate(v) > toDate(watch('start'))
                        }}
                    />
                    <Button type="submit" fluid loading={loading}>
                        Обновить
                    </Button>
                    {children}
                </Form.Group>
                {(!!errors?.start || !!errors?.end) && (
                    <Label basic style={{color: '#9f3a38', border: '1px solid #9f3a38'}}>
                        Дата начала периода должна быть раньше даты окончания
                    </Label>
                )}
            </Form>
        </FormProvider>
    );
};

function toDate(value: string): Date {
    return parse(value, isMatch(value, dateTimeFormFormat) ? dateTimeFormFormat : dateFormFormat, new Date());
}
