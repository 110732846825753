import {
    TJackpot,
    TJackpotDisplayType,
    TJackpotProps,
    TJackpotSource,
    TJackpotsPlatformSettings,
    TJackpotsPlatformSettingsProps
} from '../../../../../@types';
import { THttpErrorCode } from '../../../../../@types/HttpError/THttpErrorCode';
import { THttpErrorMessageDictionary } from '../../../../../@types/HttpError/THttpErrorMessageDictionary';

export function getAvailableSources(
    jackpots: TJackpot[] | undefined,
    sources: TJackpotSource[] | undefined,
    displayType: TJackpotDisplayType
) {
    const sourcesOfNeededType = sources!.filter((s) => sourceMatchesType(s, displayType));
    if (displayType === TJackpotDisplayType.COMMUNITY) return sourcesOfNeededType;

    const occupiedSrcCodes = (jackpots || [])
        .flatMap((jackpot) => (jackpot?.displayType === TJackpotDisplayType.INGAME ? jackpot?.sources! : []))
        .map((src) => src.code);

    return sourcesOfNeededType.filter((src) => !occupiedSrcCodes.includes(src.code));
}

export function getJackpotsByType(jackpots: TJackpot[] | undefined, type: TJackpotDisplayType): TJackpot[] {
    return (jackpots || []).filter((jp) => jp?.displayType === type) || [];
}

export function sourceMatchesType(source: TJackpotSource, type?: TJackpotDisplayType) {
    return (type === TJackpotDisplayType.INGAME && source.hasInGameJp) || (type === TJackpotDisplayType.COMMUNITY && source.hasCommunityJp);
}

export function getMbkMinBetOptions(jackpot?: TJackpot, settings?: TJackpotsPlatformSettingsProps): number[] {
    const gamesBets = settings?.bets || [];
    const sources = jackpot?.sources || [];
    const games = sources!.map((s) => s.code);
    const jpGameCodes = games.map((game) => `bet_${game}`);
    const jpGamesBetSets = gamesBets.filter((gameBets) => jpGameCodes.includes(gameBets.game));
    const minJpGamesBet = Math.max(...jpGamesBetSets.map((betsSet) => Math.min(...betsSet.bets)));
    const maxJpGamesBet = Math.min(...jpGamesBetSets.map((betsSet) => Math.max(...betsSet.bets)));

    const allBets = Array.from(
        new Set(
            gamesBets
                .flatMap((gameBets) => gameBets.bets)
                .filter((bet) => (!minJpGamesBet || bet >= minJpGamesBet) && (!maxJpGamesBet || bet <= maxJpGamesBet))
        )
    ).sort((a, b) => a - b);
    if (jpGamesBetSets.length) return [0, ...allBets];

    // remove max bets
    const take = Math.ceil(allBets.length * 0.8);
    return [0, ...allBets.slice(0, take)];
}

export function getRacesMinBetOptions(_?: TJackpot, settings?: TJackpotsPlatformSettingsProps): number[] {
    const min = settings?.minBet || 0;
    const max = settings?.maxBet || 0;

    const cents = [1, 2, 3, 4, 5, 8];
    const decimals = [10, 15, 20, 30, 40, 50, 80];

    if (!min || min <= 0) throw new Error('Min value should be positive and greater than zero');
    if (!max || max <= 0) throw new Error('Max value should be positive and greater than zero');

    let bets = [...cents, ...decimals];
    let d = 1;
    while (Math.max(...bets) < max) {
        bets = [...bets, ...decimals.map((i) => i * Math.pow(10, d))];
        d++;
    }

    return [0, ...bets.filter((b) => b >= min && b <= max)];
}

export function getNewJackpotTemplate(settings: TJackpotsPlatformSettings): TJackpotProps {
    const speed = settings?.speeds[0];
    return {
        name: ' ',
        sources: [],
        platform: settings?.platform,
        displayType: TJackpotDisplayType.INGAME,
        minAmount: 0,
        maxAmount: 0,
        amount: 0,
        minBet: 0,
        onDemandForTerm: false,
        betAmountCoefficient: 0,
        speedTag: speed?.tag,
        betPercent: speed?.betPercent,
        nextBetPercent: speed?.nextBetPercent
    };
}

export function mapJackpot(jackpot: TJackpotProps): TJackpotProps {
    return {
        ...jackpot,
        sources: jackpot.sources || [],
        minAmount: jackpot.minAmount || 0,
        maxAmount: jackpot.maxAmount || 0,
        amount: jackpot.amount || 0,
        minBet: jackpot.minBet || 0,
        onDemandForTerm: !!jackpot.onDemandForTerm,
        betAmountCoefficient: jackpot.betAmountCoefficient || 0,
        betPercent: jackpot.betPercent || 0,
        nextBetPercent: jackpot.nextBetPercent || 0
    };
}

export function mapToJackpotCreationModel(jackpot: TJackpotProps): TJackpotProps | undefined {
    if (!jackpot) return undefined;
    return {
        name: jackpot.name,
        platform: jackpot.platform,
        displayType: jackpot.displayType,
        sources: jackpot.sources!.map((s) => ({ code: s.code, type: s.type })),
        minAmount: jackpot.minAmount,
        maxAmount: jackpot.maxAmount,
        amount: jackpot.amount,
        minBet: jackpot.minBet,
        onDemandForTerm: jackpot.onDemandForTerm,
        betAmountCoefficient: jackpot.betAmountCoefficient,
        speedTag: jackpot.speedTag,
        betPercent: jackpot.betPercent,
        nextBetPercent: jackpot.nextBetPercent
    };
}

export function getJackpotsErrorMessages(msg: string): THttpErrorMessageDictionary {
    return {
        [THttpErrorCode.COMMON]: msg,
        [THttpErrorCode.DUPLICATE]: `Джекпот с таким названием уже существует`,
        [THttpErrorCode.TOO_HIGH_RTP_JACKPOT]: 'Невозможно создать джекпот. Возврат составит {totalRtp}% = ({gameRtp}% игры {source} + {jackpotRtp}% джекпота)'
    };
}
