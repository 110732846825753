import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, Label, Progress, ProgressProps, StrictFormFieldProps } from 'semantic-ui-react';
import { Form } from '../Form';
import './styles.scss';

type TProps = {
    name: string;
    label?: string;
    increment?: number;
    basic?: boolean;
    width?: StrictFormFieldProps['width'];
    disabled?: boolean;
} & ProgressProps;

export const Slider: FC<TProps> = (props) => {
    const { control } = useFormContext();
    const { name, label, increment, width, basic, disabled, ...progressProps } = props;

    const changeValue = (newValue: number, onChange: (...event: any[]) => void): void => {
        onChange(limitValue(newValue));
    };

    return (
        <Controller
            control={control}
            name={name}
            render={({ onChange, onBlur, value }) => (
                <Form.Field width={width || 16} id="slider" onBlur={onBlur} disabled={disabled}>
                    {label && <label>{label}</label>}
                    <Button as="div" labelPosition="left" style={{ width: '100%' }}>
                        <Label
                            as={Progress}
                            onBlur={onBlur}
                            pointing="left"
                            percent={limitValue(value || 0)}
                            progress
                            style={{ width: 'calc(100% - 78px)' }}
                            {...progressProps}
                        />
                        <Button.Group>
                            <Button icon="minus" onBlur={onBlur} onClick={() => changeValue((value || 0) - (increment || 1), onChange)} basic={basic} />
                            <Button icon="plus" onBlur={onBlur} onClick={() => changeValue((value || 0) + (increment || 1), onChange)} basic={basic}/>
                        </Button.Group>
                    </Button>
                </Form.Field>
            )}
        />
    );
};

function limitValue(value: number): number {
    if (value < 0) return 0;
    if (value > 100) return 100;
    return value;
}
