import { TJackpot, TJackpotProps, TPlatform, TJackpotsPlatformSettingsProps, TJackpotHistory, TDateRange } from './../@types';
import { http, getSearchParams } from '../utils/http';
import { formatForApi } from '../utils';

export const API_URL_LIST_JACKPOTS = (hallId?: string, platform?: TPlatform) =>
    `/api/support/hall/${hallId}/jackpots?${getSearchParams({ platform })}`;
export function listJackpots(hallId?: string, platform?: TPlatform): Promise<TJackpot[]> {
    return http.get(API_URL_LIST_JACKPOTS(hallId, platform)).then((r) => r.data);
}

export const API_URL_JACKPOTS_SETTINGS = (hallId?: string, platform?: TPlatform) =>
    `/api/support/hall/${hallId}/jackpots/settings?${getSearchParams({ platform })}`;
export async function getJackpotsSettings(hallId?: string, platform?: TPlatform): Promise<TJackpotsPlatformSettingsProps[]> {
    const r = await http.get<TJackpotsPlatformSettingsProps[]>(API_URL_JACKPOTS_SETTINGS(hallId, platform));

    return (
        r?.data?.map((settings) => ({
            ...settings,
            templates: settings?.templates?.map((template) => ({
                name: template.name,
                amount: template.amount || 0,
                minBet: template.minBet || 0,
                minAmount: template.minAmount || 0,
                maxAmount: template.maxAmount || 0,
                speedTag: template.speedTag || 'Custom',
                betPercent: template.betPercent || 0,
                nextBetPercent: template.nextBetPercent || 0,
                shared: !!template.shared
            }))
        })) || []
    );
}

export const API_URL_GET_JACKPOTS_HISTORY = (hallId?: string, platform?: TPlatform, dateRange?: TDateRange) =>
    `/api/support/hall/${hallId}/jackpots/history?${
        dateRange && getSearchParams({ platform, start: formatForApi(dateRange.start), end: formatForApi(dateRange.end) })
    }`;
export function getJackpotsHistory(hallId?: string, platform?: TPlatform, dateRange?: TDateRange): Promise<TJackpotHistory[]> {
    return http.get(API_URL_GET_JACKPOTS_HISTORY(hallId, platform, dateRange)).then((r) => r.data);
}

export function createJackpot(hallId?: string, jackpot?: TJackpotProps): Promise<void> {
    return http.put(`/api/support/hall/${hallId}/${jackpot?.platform}/jackpots`, jackpot);
}

export function createOnlineJackpots(hallId: string, jackpots?: TJackpotProps[]): Promise<void> {
    return http.put(`/api/support/hall/${hallId}/online/jackpots`, { jackpots });
}

export function deleteJackpot(hallId: string, jackpot?: TJackpotProps): Promise<void> {
    return http.delete(`/api/support/hall/${hallId}/${jackpot?.platform}/jackpots/${jackpot?.id}`);
}

export function resetJackpot(hallId: string, jackpot?: TJackpotProps): Promise<void> {
    return http.post(`/api/support/hall/${hallId}/${jackpot?.platform}/jackpots/${jackpot?.id}/reset`);
}

export function updateJackpot(hallId: string, jackpot?: TJackpotProps): Promise<void> {
    return http.post(`/api/support/hall/${hallId}/${jackpot?.platform}/jackpots/${jackpot?.id}`, jackpot);
}
