import React, { FC } from 'react';
import { Route, Redirect, RouteComponentProps, RouteProps } from 'react-router';
import { useAuth } from '../hooks/useAuth';

export const PrivateRoute: FC<RouteProps> = ({ component: Component, ...rest }) => {
    const { user, isAuth } = useAuth();

    if (!Component || (user && !isAuth)) {
        return null;
    }
    return (
        <Route
            {...rest}
            render={(props: RouteComponentProps<{}>) =>
                isAuth ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }
        />
    );
};
