import React, { FC } from 'react';
import { Input as SemanticReactInput, InputProps } from 'semantic-ui-react';
import { Controller, useFormContext } from 'react-hook-form';

export const Input: FC<InputProps> = (props) => {
    const { control } = useFormContext();
    return (
        <Controller
            control={control}
            name={props.name}
            render={({ onChange, onBlur, value }) => <SemanticReactInput onChange={onChange} onBlur={onBlur} value={value || ''} {...props} />}
        />
    );
};
