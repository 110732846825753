import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.scss';

import { PrivateRoute, SuspenseContainer } from './@components';
import { AuthContext } from './@contexts';
import { useAuthContext } from './hooks';

const LoginPage = lazy(() => import('./@pages/LoginPage/index'));
const MainPage = lazy(() => import('./@pages/MainPage'));
const NotFoundPage = lazy(() => import('./@pages/NotFoundPage'));

const App = () => {
    return (
        <AuthContext.Provider value={useAuthContext()}>
            <SuspenseContainer>
                <Switch>
                    <Route path="/login" component={() => <LoginPage />} />
                    <PrivateRoute path="/" component={() => <MainPage />} />
                    <Route path="" component={NotFoundPage} />
                </Switch>
            </SuspenseContainer>
        </AuthContext.Provider>
    );
};
export default App;
