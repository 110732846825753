import React, { FC, ReactNode } from 'react';
import { FormDropdown as SemanticReactFormDropdown, FormDropdownProps } from 'semantic-ui-react';
import { Controller, useFormContext } from 'react-hook-form';

type TProps = {
    name: string;
    getText?: (value: string | number | undefined) => string | ReactNode;
} & FormDropdownProps;

export const Dropdown: FC<TProps> = (props) => {
    const { control } = useFormContext();
    const { name, getText, ...formDropdownProps } = props;

    return (
        <Controller
            control={control}
            name={name}
            render={({ onChange, onBlur, value }) => (
                <SemanticReactFormDropdown
                    onChange={(_, data) => onChange(data?.value)}
                    onBlur={onBlur}
                    value={value}
                    text={!!getText ? getText(value) : value}
                    {...formDropdownProps}
                />
            )}
        />
    );
};
