
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TRouteParams, TReceiptResult } from '../@types';
import { API_URL_GET_SPORT_LIVE_CLIENT, getSportLiveClientIframe } from '../services';


export function useSportLiveClient() {
    const [data, setData] = useState<TReceiptResult | null>(null);

    useEffect(() => {
        async function fetchData() {
            const response = await getSportLiveClientIframe();
            setData(response);
        }
        fetchData();
    }, []);

    return data;
}
