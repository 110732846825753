import { THallRtpSettings, THallFinalBonusSettings, TUserActivity, TDateRange, THallProps, THall } from '../@types';
import { formatForApi } from '../utils';
import { http, getSearchParams } from '../utils/http';

export function findHall(hallNumber?: string): Promise<THallProps> {
    return http.get(`/api/support/halls/find/${hallNumber}`).then((r) => r.data);
}

export const API_URL_GET_HALL = (hallId?: string) => `/api/support/halls/${hallId}`;
export function getHall(hallId?: string): Promise<THall> {
    if (!hallId) return Promise.resolve(undefined);
    return http.get(API_URL_GET_HALL(hallId)).then((r) => r.data);
}

export function setFinalBonus(id?: string, model?: THallFinalBonusSettings) {
    return http.post(`/api/support/halls/${id}/bonus/final`, model).then((r) => r.data);
}

export function setRtp(id?: string, model?: THallRtpSettings) {
    return http.post(`/api/support/halls/${id}/rtps`, model).then((r) => r.data);
}

export const API_URL_GET_USERS_ACTIVITY = (hallId?: string, dateRange?: TDateRange) =>
    `/api/support/halls/${hallId}/users/activity?${
        dateRange && getSearchParams({ start: formatForApi(dateRange.start), end: formatForApi(dateRange.end) })
    }`;
export function getUsersActivity(hallId?: string, dateRange?: TDateRange): Promise<TUserActivity[]> {
    return http.get(API_URL_GET_USERS_ACTIVITY(hallId, dateRange)).then((r) => r.data);
}
