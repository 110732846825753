import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Icon } from 'semantic-ui-react';
import { TMenuItem } from '../../@types';
import './styles.scss';

export const MenuItem: FC<TMenuItem> = ({ link, icon, header, description, disabled, color }: TMenuItem) => {
    const foreignLinkMode = link.includes('http');
    const linkComponent = foreignLinkMode ? 'a' : NavLink;
    return (
        <Menu.Item
            as={!disabled ? linkComponent : null}
            href={!disabled ? link : null}
            to={link}
            color={color}
            target={foreignLinkMode ? '_blank' : null}
        >
            <div style={{ display: 'flex', flexDirection: 'column', color: disabled ? 'gray' : 'black' }}>
                <h3 style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Icon name={icon || 'arrow alternate circle right outline'} />
                    <span>{header || link}</span>
                </h3>
                {description && <div>{description}</div>}
            </div>
        </Menu.Item>
    );
};
