import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { TRouteParams } from '../@types';
import { API_URL_GET_DEVICE, getDevice } from '../services';

export function useDevice() {
    const { hallId, deviceId } = useParams<TRouteParams>();
    const { data, error, mutate } = useSWR(API_URL_GET_DEVICE(hallId, deviceId), () =>
        hallId && deviceId ? getDevice(hallId, deviceId) : undefined
    );

    return {
        device: !!deviceId ? data : undefined,
        isLoading: !!deviceId && !error && !data,
        mutate,
        error
    };
}
