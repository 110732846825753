import useSWR from 'swr';

import { API_URL_GET_JACKPOTS_HISTORY, getJackpotsHistory } from '../services';
import { useRouting } from './useRouting';

export function useJackpotsHistory() {
    const { hallId, platform, dateRange } = useRouting();

    const { data, error } = useSWR(
        API_URL_GET_JACKPOTS_HISTORY(hallId, platform, dateRange),
        () => getJackpotsHistory(hallId, platform, dateRange)
    );

    return {
        history: data,
        isLoading: !error && !data,
        error
    };
}
