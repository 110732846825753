import React, { FC, useEffect, useContext } from 'react';
import { MediaContext } from '../@contexts';

type TProps = {
    receiptUrl: string;
    containerId: string;
    printOnly?: Boolean;
    onReceiptLoaded?: (iframe: HTMLIFrameElement) => void;
};

export const Receipt: FC<TProps> = ({ receiptUrl, containerId, printOnly, onReceiptLoaded }) => {
    const layout = useContext(MediaContext);
    const containerStyle = {
        overflow: 'hidden',
        display: 'block',
        height: printOnly ? '0' : '',
        width: printOnly ? '0' : ''
    };

    useEffect(() => {
        const iframe = document.getElementById(containerId) as HTMLIFrameElement;
        iframe.style.height = 'auto';
        iframe.style.width = 'auto';

        setTimeout(() => {
            if (iframe && receiptUrl) {
                iframe.src = receiptUrl;
            }
        });
    }, [receiptUrl, containerId]);

    const onLoad = () => {
        const iframe = document.getElementById(containerId) as HTMLIFrameElement;
        setTimeout(() => {
            if (iframe.contentDocument) {
                iframe.style.height = `${iframe.contentDocument.body.scrollHeight}px`;
                iframe.style.width = layout.isMobile ? '100%' : `${iframe.contentDocument.body.scrollWidth}px`;
            }
            onReceiptLoaded && onReceiptLoaded(iframe);
        });
    };

    return (
        <div style={containerStyle} id="receipt-container">
            <iframe
                title="receipt"
                id={containerId}
                frameBorder="0"
                style={{ overflow: 'hidden', width: '100%' }}
                onLoad={onLoad}
                className="receipt-frame"
            />
        </div>
    );
};
