import { TDateRange } from './../@types/TDateRange';
import { TPageNumber } from './../@types/TPageNumber';
import { parseJSON, formatISO, format, addDays, addSeconds, toDate, startOfDay, subDays, endOfDay } from 'date-fns';

export const dateFormFormat = 'yyyy-MM-dd';
export const dateTimeFormFormat = 'yyyy-MM-dd HH:mm';
export const dateTimeUrlFormat = 'yyyy-MM-dd HH:mm:ss';

export function toDateTime(value?: string): string {
    return value ? format(parseJSON(value), 'yyyy-MM-dd HH:mm:ss') : '';
}

export function dateToQueryString(key: string, date?: Date) {
    return `${key}=${date ? format(date, dateTimeUrlFormat) : ''}`;
}

export function dateRangeToQueryString(dateRange?: TDateRange) {
    return `${dateToQueryString('start', dateRange?.start)}&${dateToQueryString('end', dateRange?.end)}`;
}

export function searchTransactionsToQueryString(dateRange?: TDateRange, currentPage?: any | undefined) {
    return `${dateToQueryString('start', dateRange?.start)}&${dateToQueryString('end', dateRange?.end)}&pageNumber=${currentPage}`;
}

export const defaultDateRange: TDateRange = {
    start: startOfDay(toDate(Date.now())),
    end: addSeconds(startOfDay(addDays(Date.now(), 1)), -1)
};

export const defaultDateRangeTransactions: TDateRange = {
    start: startOfDay(subDays(Date.now(), 7)),
    end: startOfDay(addDays(Date.now(), 1))
};

export function formatForApi(date?: Date) {
    return date ? formatISO(date).substr(0, 19) + 'Z' : ''
}
