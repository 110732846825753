import { TRouteParams } from './../@types';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { API_URL_GET_KIOSK, getKiosks } from '../services';


export function useKiosks() {
    const { kioskSearchCategory, kioskSearchValue } = useParams<TRouteParams>();

    const { data, error, mutate } = useSWR(JSON.stringify(API_URL_GET_KIOSK(kioskSearchCategory, kioskSearchValue)), () => getKiosks(kioskSearchCategory, kioskSearchValue));

    return {
        kiosks: data,
        searchCategory: kioskSearchCategory,
        searchValue: kioskSearchValue,
        isLoading: !error && !data,
        error,
        mutate
    };
}
